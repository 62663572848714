import { graphql, useStaticQuery } from "gatsby"

export const useSiteProjectsPageI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiProjectsPage {
        localizations {
          data {
            attributes {
              title
              subtitle
            }
          }
        }
      }
    }
  `)

  return data.strapiProjectsPage.localizations.data[0].attributes;

}