import { graphql, Link, StaticQueryDocument, useStaticQuery } from 'gatsby';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { useState } from 'react';
import DefaultAppView from '../app-view/default-app-view';
import { SEO } from '../components/seo';
import { getTheme } from '../functions/theme';
import { useSiteStrapiProjectsPage } from '../hooks/site-strapi-projects-page';
import { SEOData } from '../constants/types';
import { getUserPreferredLocale } from '../functions/utils';
import { useSiteProjectsPageI18N_EN } from '../hooks/site-strapi-i18n-EN/site-projects-page-i18n-EN';
import { useSiteProjectsPageI18N_IT } from '../hooks/site-strapi-i18n-IT/site-projects-page-i18n-IT';

// PROJECTS
const Projects = () => {

  const currentTheme: 'light' | 'dark' = getTheme();
  const _userLocale: string = getUserPreferredLocale();

  // --- Getting datas w/ querys from strapi 
  const staticQuery: any | undefined = useSiteStrapiProjectsPage();
  const strapiI18NContent: any = (_userLocale.includes('it') || _userLocale.includes('IT')) ? useSiteProjectsPageI18N_IT() : useSiteProjectsPageI18N_EN();

  const _projects: any[] = staticQuery && staticQuery?.edges.map((el: any) => {
    return {
      nodeImage: el.node.picture.localFile,
      priority: el.node.priority,
      title: el.node.title,
      id: el.node.id
    };
  }).sort((a: any, b: any) => a.priority && b.priority && (a.priority - b.priority));
  // ---

  // --- STATES 
  const [projects, setProjects] = useState(_projects);
  // ---

  return (
    <DefaultAppView currentUrl='/projects' userLocale={_userLocale}>
      <div className={`px-5 lg:px-36 ${currentTheme === 'light' ? 'bg-cardinal-white text-black' : 'bg-black text-cardinal-white'}`}>
        <div className='pt-20 lg:pt-40'>
          <div className='text-xs uppercase lg:text-lg lg:flex'>{ strapiI18NContent?.title }</div>
        </div>
      </div>
      <div className={`flex flex-col min-h-screen px-5 lg:px-36 lg:pt-7 xl:pb-80 ${currentTheme === 'light' ? 'bg-cardinal-white text-black' : 'bg-black text-cardinal-white'}`}>
        <div className={`w-full lg:pb-40 lg:border-b ${currentTheme === 'light' ? 'border-black' : 'border-cardinal-white'}`}>
          <div className='w-full text-3xl font-semibold xl:text-7xl md:w-11/12 3xl:w-4/5 3xl:text-8xl'>
            { strapiI18NContent?.subtitle }
          </div>
        </div>
        <div className='flex flex-wrap pt-16 space-y-7 lg:space-y-0 lg:space-x-16'>
          { projects && 
            projects.map((el: any) => {
              const _image: IGatsbyImageData = el.nodeImage && getImage(el.nodeImage);
              const _project: string = el?.title && el.title.toLowerCase();
              return ( el &&
                <div className="w-full h-full md:w-[45%]" key={el.id}>
                  <div className='pb-3 lg:pb-9'>
                    <Link to={`/project-detail/${_project}`}>
                      <GatsbyImage image={_image} alt={el.title} className="flex-1 w-full h-full btn-like"/>
                    </Link>
                  </div>
                  <span className='text-2xl font-medium lg:font-semibold lg:text-3xl'>{el.title}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    </DefaultAppView>
  )
}

export default Projects;

// ----------------------- SEO CREATION ---------------------- //

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    strapiProjectsPage {
      SEO {
        metaTitle
        metaDescription
        keywords
        preventIndexing
        pathname
      }
    }
  }
`

export const Head = () => {
  const seo: SEOData = seoData && useStaticQuery(seoData).strapiProjectsPage.SEO;

  return (
    <SEO 
      title={seo.metaTitle} 
      description={seo.metaDescription} 
      keywords={seo.keywords} 
      preventIndexing={seo.preventIndexing} 
      pathname={seo.pathname} />   
  )
}