import { graphql, useStaticQuery } from "gatsby";

// HOOK - From STRAPI | Collection Type: Project | Getting Project detail Images
export const useSiteStrapiProjectsPage = () => {
  const data: any = useStaticQuery(graphql`
    query {
      allStrapiProject {
        edges {
          node {
            id
            title
            priority
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allStrapiProject;

}